if (!Element.prototype.matches) {
	Element.prototype.matches =
	Element.prototype.msMatchesSelector || 
	Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;

		do {
			if (Element.prototype.matches.call(el, s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}
import { contactSubmit } from './contact.js';
import { getMapsHelper } from './util/mapsHelper';
import { initDoubleClick } from './header';

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.querySelector('#map_canvas')) {
	const mapHelper = getMapsHelper();
	mapHelper.ready().then(() => {
		const theMap = mapHelper.createMap({
			element: document.querySelector('#map_canvas'),
			locationElementSelector: '#map_canvas'
		});
		// eslint-disable-next-line no-undef
		google.maps.event.addListenerOnce(theMap, 'idle', () => {
			theMap.setZoom(13);
		});
	});
}

// Double tapper
if(document.querySelector('.d_click')) {
	initDoubleClick();
}

/*
 * OLD JQUERY SCRIPTS BELOW
 */


/* eslint-disable */

$(document).ready(function() {

	/* Mobile Nav */
	$('.mobile_nav').click(function(){
		$('#mobilnav').slideToggle();
	});

	if (
		$(window).width() < 700) {
		$('#home_description, #featuresleft, #featuresright, #offerTwo, #climateControlmobi').hide();
		   $('#mobacordtop span.mobile').click(function() {
		       $('#home_description, #featuresleft, #featuresright, #offerTwo, #climateControlmobi').slideToggle();
		});

		$('#climateControlmobi, .referral').hide();
		   $('#refersection span.mobile').click(function() {
		       $('#climateControlmobi, .referral').slideToggle();
		});

		$('#horloc').hide();
		   $('#sidebar span.mobile').click(function() {
		       $('#horloc').slideToggle();
		});

		$('.toolsbtns').hide();
		   $('#socialBox span.mobile').click(function() {
		       $('.toolsbtns').slideToggle();
		});
		}
	$('nav li a').not("[target='_blank']").on('click touchend', function(){
		window.location = $(this).attr('href');
	});

	// nav-script
	$('#navigationMenu li .normalMenu').each(function(){
		$(this).before($(this).clone().addClass('hoverMenu'));
	});
	$('#navigationMenu li').hover(function(){
		$(this).find('.hoverMenu').stop().animate({marginTop:'0px'},200);
	},
	function(){
		$(this).find('.hoverMenu').stop().animate({marginTop:'-60px'},200);
	});

	// popUp virtual tour
	window.showPopUp = function(id, secondId) {
	   document.getElementById(id).style.visibility = "visible";
	   document.getElementById(secondId).style.visibility = "visible";
	}
	window.hidePopUp = function(id, secondId) {
		document.getElementById(id).style.visibility = "hidden";
		document.getElementById(secondId).style.visibility = "hidden";
	}

/***********************************************************************************************************************
DOCUMENT: includes/javascript.js
DEVELOPED BY: Ryan Stemkoski
COMPANY: Zipline Interactive
EMAIL: ryan@gozipline.com
PHONE: 509-321-2849
DATE: 3/26/2009
UPDATED: 3/25/2010
DESCRIPTION: This is the JavaScript required to create the accordion style menu.  Requires jQuery library
NOTE: Because of a bug in jQuery with IE8 we had to add an IE stylesheet hack to get the system to work in all browsers. I hate hacks but had no choice :(.
************************************************************************************************************************/
	//ACCORDION BUTTON ACTION (ON CLICK DO THE FOLLOWING)
	$('.menuButton').click(function() {

		//REMOVE THE ON CLASS FROM ALL BUTTONS
		$('.menuButton').removeClass('on');

		//NO MATTER WHAT WE CLOSE ALL OPEN SLIDES
	 	$('.menuContent').slideUp('normal');

		//IF THE NEXT SLIDE WASN'T OPEN THEN OPEN IT
		if($(this).next().is(':hidden') == true) {

			//ADD THE ON CLASS TO THE BUTTON
			$(this).addClass('on');

			//OPEN THE SLIDE
			$(this).next().slideDown('normal');
			$('nav').animate({'margin-bottom':'155px'});
		 }
		 else {
			$('nav').animate({'margin-bottom':'10px'});
		 }

	 });


	/*** REMOVE IF MOUSEOVER IS NOT REQUIRED ***/

	//ADDS THE .OVER CLASS FROM THE STYLESHEET ON MOUSEOVER
	$('.menuButton').mouseover(function() {
		$(this).addClass('over');

	//ON MOUSEOUT REMOVE THE OVER CLASS
	}).mouseout(function() {
		$(this).removeClass('over');
	});

	/*** END REMOVE IF MOUSEOVER IS NOT REQUIRED ***/


	/********************************************************************************************************************
	CLOSES ALL S ON PAGE LOAD
	********************************************************************************************************************/
	$('.menuContent').hide();

});

/* For the Map/Phone buttons at the footer */
if ($(window).width() < 700) {
	$(window).scroll(function(){
		if ($(this).scrollTop() > 70) {
			$('#map_phone').slideDown('fast');
		} else {
			$('#map_phone').slideUp('fast');
		}
	});
}

$(document).ready(function(){

	$('#navigationMenu li .normalMenu').each(function(){

		$(this).before($(this).clone().addClass('hoverMenu'));

	});
	
	$('#navigationMenu li').hover(function(){
	
		$(this).find('.hoverMenu').stop().animate({marginTop:'0px'},200);

	},
	
	function(){
	
		$(this).find('.hoverMenu').stop().animate({marginTop:'-60px'},200);

	});
	
});


/***********************************************************************************************************************
DOCUMENT: includes/javascript.js
DEVELOPED BY: Ryan Stemkoski
COMPANY: Zipline Interactive
EMAIL: ryan@gozipline.com
PHONE: 509-321-2849
DATE: 3/26/2009
UPDATED: 3/25/2010
DESCRIPTION: This is the JavaScript required to create the accordion style menu.  Requires jQuery library
NOTE: Because of a bug in jQuery with IE8 we had to add an IE stylesheet hack to get the system to work in all browsers. I hate hacks but had no choice :(.
************************************************************************************************************************/
$(document).ready(function() {
	 
	//ACCORDION BUTTON ACTION (ON CLICK DO THE FOLLOWING)
	$('.accordionButton').click(function() {

		//REMOVE THE ON CLASS FROM ALL BUTTONS
		$('.accordionButton').removeClass('on');
		  
		//NO MATTER WHAT WE CLOSE ALL OPEN SLIDES
	 	$('.accordionContent').slideUp('normal');
   
		//IF THE NEXT SLIDE WASN'T OPEN THEN OPEN IT
		if($(this).next().is(':hidden') == true) {
			
			//ADD THE ON CLASS TO THE BUTTON
			$(this).addClass('on');
			  
			//OPEN THE SLIDE
			$(this).next().slideDown('normal');
		 } 
		  
	 });
	  
	
	/*** REMOVE IF MOUSEOVER IS NOT REQUIRED ***/
	
	//ADDS THE .OVER CLASS FROM THE STYLESHEET ON MOUSEOVER 
	$('.accordionButton').mouseover(function() {
		$(this).addClass('over');
		
	//ON MOUSEOUT REMOVE THE OVER CLASS
	}).mouseout(function() {
		$(this).removeClass('over');										
	});
	
	/*** END REMOVE IF MOUSEOVER IS NOT REQUIRED ***/
	
	
	/********************************************************************************************************************
	CLOSES ALL S ON PAGE LOAD
	********************************************************************************************************************/	
	$('.accordionContent').hide();

});